.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    margin-bottom: 15px;
}

/*custom column actions size*/
.actions-column {
    width: 5%;
    text-align: center;
}

/*add button align right*/
.button-container {
    text-align: right;
    margin-bottom: 10px;
}

/*custom table */
.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: middle;
    border-top: 1px solid #dddddd;
}

/*label input checkobox*/
.label-check {
    margin-right: 10px;
    vertical-align: middle;
}

/*Alert message*/
.success-container {
    position: fixed;
    top: 15%;
    right: 5%;
    width: auto;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}

.success-message {
    background-color: #81C784;
    color: white;
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #66BB6A;
    display: inline-block;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
}

/* Error Alert message */
.error-container {
    position: fixed;
    top: 15%;
    right: 5%;
    width: auto;
    display: flex;
    justify-content: flex-end;
    z-index: 1000;
}

.error-message {
    background-color: #ffe6e6;
    /* Fondo rojo claro */
    color: #a94442;
    /* Texto rojo oscuro */
    padding: 15px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    border: 1px solid #f5c2c7;
    /* Borde rojo intermedio */
    display: inline-block;
    width: 100%;
    max-width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.5s ease-in-out;
}


/* Animación para hacer que el mensaje aparezca */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/*button group*/
.button-group {
    display: grid;
    /*gap: 10px;*/
    /* Espaciado entre botones */
    /*margin-top: 10px;*/
    /* Margen opcional para separar botones del cropper */
}

.button-group .btn {
    flex-shrink: 0;
    /* Asegura que los botones no cambien de tamaño */
}

/*red text message*/
.error-message-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

/*Customer*/
.customer-subtitle {
    color: #0088cc !important;
    font-size: 20px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
}

.label-middle {
    vertical-align: middle !important;
}

.form-check-input {
    display: inline-block !important;
    margin-right: 10px !important;
}

.space-right {
    margin-right: 10px !important;
}

/*preview image grid*/
.preview-image-grid {
    width: 50px;
    height: 50px;
}

.preview-image-modal {
    width: 100px;
    height: 100px;
    margin-right: 10px;
    margin-bottom: 10px;
}

/*Button align left*/
.align-left {
    float: left;
}

/*upload images*/
.image-uploader-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    /*padding: 20px;*/
    flex-wrap: wrap;
    /* Permite que los elementos se ajusten en pantallas más pequeñas */
}

.left-panel,
.right-panel {
    flex: 1;
}

.left-panel {
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
}

.right-panel {
    /*border: 1px solid #ccc;*/
    /*padding: 10px;*/
    text-align: center;
    width: 200px !important;
    margin-top: 20px;
}

/* Zona de drop */
.dropzone {
    border: 2px dashed #ccc;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    width: 200px;
    height: 200px;
}

/* Contenedor del cropper */
.cropper-container {
    width: 100%;
}

.cropped-canvas {
    width: 200px;
    height: 200px;
}

.cropper-size {
    height: 300px;
    width: 100%;
}

/* Previsualización recortada */
.cropped-preview img {
    max-width: 100%;
    width: 200px;
    height: 200px;
    height: auto;
}

.button-crop-margin {
    margin: 10px;
}

/* Media Query para pantallas pequeñas (móviles) */
@media (max-width: 768px) {
    .image-uploader-container {
        flex-direction: column;
        /* Coloca los paneles uno encima del otro */
        align-items: center;
        /* Centra los elementos */
    }

    .left-panel,
    .right-panel {
        width: 100%;
        /* Asegura que ambos paneles ocupen el 100% del ancho */
        margin-top: 20px;
    }

    .right-panel {
        width: auto;
        /* Hace que el .right-panel ocupe solo el espacio necesario */
    }

    .dropzone {
        width: 100%;
        /* Hace que la zona de drop ocupe todo el ancho disponible */
        height: auto;
        /* Hace que la altura sea proporcional */
    }

    .cropped-preview img {
        width: 100%;
        /* Hace que la imagen recortada ocupe todo el ancho disponible */
        height: auto;
    }

}

/*ColorPicker component custom*/
.color-picker-button-size {
    max-width: 85px !important;
    width: 85px;
}

.color-picker-container {
    display: flex;
    align-items: center;
    gap: 10px;
}

.color-picker-input {
    width: 100%;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
}

.color-display-btn {
    display: inline-block;
    padding: 6px 12px;
    border-radius: 5px;
    /*color: #fff;*/
    font-weight: bold;
    cursor: pointer;
    text-align: center;
}

.color-display-btn::-webkit-color-swatch {
    border: none;
}

/*pestañas*/
.tabs button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: #f0f0f0;
    border-radius: 20px;
    /* Hace los botones redondeados */
    margin: 5px;
    /* Añade espacio entre los botones */
    transition: background 0.3s ease, transform 0.2s ease;
    /* Animaciones suaves */
}

.tabs button:hover {
    background: #e0e0e0;
    /* Color al pasar el ratón */
    transform: scale(1.05);
    /* Pequeño efecto de zoom al pasar el ratón */
}

.tabs button.active {
    background: #007bff;
    color: white;
    font-weight: bold;
    border-radius: 20px;
    /* Mantiene los bordes redondeados */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Añade sombra para resaltar la pestaña activa */
}


/*Modal product details*/
.full-screen-overlay {
    background-color: rgba(0, 0, 0, 0.8);
    /* Fondo oscuro para el overlay */
}

.full-screen-modal {
    position: fixed;
    top: 20px; /* Ajusta el margen superior */
    left: 20px; /* Ajusta el margen izquierdo */
    right: 20px; /* Ajusta el margen derecho */
    bottom: 20px; /* Ajusta el margen inferior */

    background-color: #000000;
    /* Fondo blanco para el contenido del modal */
    overflow: auto;
    z-index: 1050;
}

/*Iframe modal*/
.iframe-modal {
    width: 100%;
    height: calc(100% - 40px);
    border: 1px #000000
}