/* Estilo general del dashboard */
.dashboard {
    padding: 20px;
    background-color: #2c3e50; /* Fondo verde oscuro */
    background-image: linear-gradient(145deg, #34495e 25%, #2c3e50 75%); /* Textura */
    min-height: 100vh;
    font-family: 'Chalkboard SE', sans-serif; /* Fuente estilo pizarra */
    display: flex; /* Activa el modelo flexbox */
    flex-wrap: wrap; /* Permite que las tarjetas se muevan a la siguiente línea si no caben */
    gap: 20px; /* Espaciado entre tarjetas */
    justify-content: flex-start; /* Alinea las tarjetas al inicio (puedes cambiar esto) */
    align-items: flex-start; /* Alineación vertical */
  }

/* Estilo de cada post-it */
.post-it {
    position: relative;
    /* Para posicionar la chincheta */
    width: 250px;
    padding: 15px;
    background-color: #FFF8DC;
    /* Fondo de las comandas */
    border-radius: 5px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.5);
    /* Sombra más intensa para resaltar */
    margin-bottom: 20px;
    cursor: move;
    font-family: 'Courier New', Courier, monospace;
    line-height: 1.5;
    transform: rotate(-2deg);
    /* Leve rotación para un efecto dinámico */
}

/* Estilo de la chincheta */
.post-it-pin {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 20px;
    height: 20px;
    background-color: #c0392b;
    /* Color rojo de la chincheta */
    border-radius: 50%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    /* Sombra para efecto 3D */
    z-index: 1;
}

/* Título del post-it */
.post-it-title {
    margin: 0 0 10px 0;
    border-bottom: 1px solid #c5a880;
    padding-bottom: 5px;
    color: #5d4037;
}

/* Lista de items */
.post-it-list {
    padding: 0 10px;
}

.post-it-item {
    list-style: none;
    color: #6b4226;
    display: flex;
    /* Alinea texto e ícono horizontalmente */
    justify-content: space-between;
    /* Alinea texto e ícono en extremos opuestos */
}

/* Total del pedido */
.post-it-total {
    margin-top: 10px;
    font-weight: bold;
    color: #5d4037;
    text-align: right;
}